import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const AdminService = {};
AdminService.getAdmins = function (page) {
  return fetch({
    method: "get",
    url: `api/admin/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AdminService.getAllControllers = function () {
  return fetch({
    method: "get",
    url: `api/admin/getAllControllers`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AdminService.createAdmin = function (data) {
  return fetch({
    method: "post",
    url: `api/admin/createAdmin`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AdminService.createSuperAdmin = function (data) {
  return fetch({
    method: "post",
    url: `api/admin/superAdmin/createSuperAdmin`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AdminService.getAdmin = function (id) {
  return fetch({
    method: "get",
    url: `api/admin/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AdminService.deleteAdmin = function (id) {
  return fetch({
    method: "delete",
    url: `api/admin/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AdminService.updateAdminToSuperAdmin = function (id) {
  return fetch({
    method: "put",
    url: `api/admin/updateAdminToSuperAdmin/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AdminService.updateSuperAdminToAdmin = function (id) {
  return fetch({
    method: "put",
    url: `api/admin/updateSuperAdminToAdmin/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AdminService.deleteAdmins = function () {
  return fetch({
    method: "delete",
    url: `api/admin/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default AdminService;
