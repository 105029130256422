import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const StatsService = {};
StatsService.getNumberOfCustomers = function () {
  return fetch({
    method: "get",
    url: `api/dashboard/getNumberOfCustomers`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
StatsService.getNumberOfAmbassadors = function () {
  return fetch({
    method: "get",
    url: `api/dashboard/getNumberOfAmbassadors`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
StatsService.getNumberOfAmbassadorsAndCustomers = function () {
  return fetch({
    method: "get",
    url: `api/dashboard/getNumberOfAmbassadorsAndCustomers`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
StatsService.getNumberOfCompanies = function () {
  return fetch({
    method: "get",
    url: `api/dashboard/getNumberOfCompanies`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
StatsService.getNumberOfRelationships = function () {
  return fetch({
    method: "get",
    url: `api/dashboard/getNumberOfRelationships`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
StatsService.getBestCompanies = function () {
  return fetch({
    method: "get",
    url: `api/dashboard/getBestCompanies`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
StatsService.getUsageRateOfSpecificFeatures = function () {
  return fetch({
    method: "get",
    url: `api/dashboard/getUsageRateOfSpecificFeatures`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
StatsService.getUsageRateOfSpecificFeaturesByDate = function (date) {
  return fetch({
    method: "get",
    url: `api/dashboard/getUsageRateOfSpecificFeatures?creationDate=${date}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
StatsService.getWinGuideVotes = function () {
  return fetch({
    method: "get",
    url: `api/dashboard/getWinGuideVotes`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};

export default StatsService;
