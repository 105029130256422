import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DonationService from "services/DonationService";

export const getAllDonations = createAsyncThunk(
  "donations/getAllDonations",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await DonationService.getDonations(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createDonation = createAsyncThunk(
  "donation/createDonation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DonationService.createDonation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

// export const updateDonation = createAsyncThunk(
//   "donations/updateDonation",
//   async ({ id, data }, { rejectWithValue }) => {
//     try {
//       const response = await DonationService.updateDonation(id, data);
//       return { id, updatedDonation: response.data };
//     } catch (error) {
//       return rejectWithValue(error.response || "Erreur");
//     }
//   }
// );

// export const deleteDonation = createAsyncThunk(
//   "donations/deleteDonation",
//   async (id, { rejectWithValue }) => {
//     try {
//       await DonationService.deleteDonation(id);
//       return id;
//     } catch (error) {
//       return rejectWithValue(error.response || "Erreur");
//     }
//   }
// );

export const fetchOneDonation = createAsyncThunk(
  "donations/fetchOneDonation",
  async (id, { rejectWithValue }) => {
    try {
      const response = await DonationService.getDonation(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  donations: [],
  donation: {},
  loading: false,
  error: null,
};

const donationSlice = createSlice({
  name: "donations",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch donations
      .addCase(getAllDonations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllDonations.fulfilled, (state, action) => {
        state.loading = false;
        state.donations = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllDonations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // Create Donation
      .addCase(createDonation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDonation.fulfilled, (state, action) => {
        state.donations.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createDonation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update donation
      //   .addCase(updateDonation.pending, (state) => {
      //     state.loading = true;
      //     state.error = null;
      //   })
      //   .addCase(updateDonation.fulfilled, (state, action) => {
      //     const { id, updatedDonation } = action.payload;
      //     const index = state.donations.findIndex((donation) => donation.id === id);
      //     if (index !== -1) {
      //       state.donations[index] = {
      //         ...state.donations[index],
      //         ...updatedDonation,
      //       };
      //     }
      //     state.loading = false;
      //     state.error = null;
      //   })
      //   .addCase(updateDonation.rejected, (state, action) => {
      //     state.loading = false;
      //     state.error = action.error.message;
      //   })
      //   // Delete donation
      //   .addCase(deleteDonation.pending, (state) => {
      //     state.loading = true;
      //     state.error = null;
      //   })
      //   .addCase(deleteDonation.fulfilled, (state, action) => {
      //     const donationId = action.payload;
      //     state.donations = state.donations.filter(
      //       (donation) => donation._id !== donationId
      //     );
      //     state.loading = false;
      //     state.error = null;
      //   })
      //   .addCase(deleteDonation.rejected, (state, action) => {
      //     state.loading = false;
      //     state.error = action.payload;
      //   })

      .addCase(fetchOneDonation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneDonation.fulfilled, (state, action) => {
        state.loading = false;
        state.donation = action.payload;
        state.error = null;
      })
      .addCase(fetchOneDonation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default donationSlice.reducer;
export const { showLoading, hideLoading, hideError } = donationSlice.actions;
