import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const DonationService = {};
DonationService.getDonations = function (page) {
  return fetch({
    method: "get",
    url: `api/donations/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
DonationService.createDonation = function (data) {
  return fetch({
    method: "post",
    url: `api/donations/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
DonationService.getDonation = function (id) {
  return fetch({
    method: "get",
    url: `api/donations/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
// DonationService.deleteDonation = function (id) {
//   return fetch({
//     method: "delete",
//     url: `api/donations/deleteById/${id}`,
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
//     },
//   });
// };
// DonationService.updateDonation = function (id, data) {
//   return fetch({
//     method: "put",
//     url: `api/donations/update/${id}`,
//     data: data,
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
//     },
//   });
// };
// DonationService.deleteDonations = function () {
//   return fetch({
//     method: "delete",
//     url: `api/donations/deleteAll`,
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
//     },
//   });
// };
export default DonationService;
