import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import NotificationService from "services/NotificationService";

export const getAllNotifications = createAsyncThunk(
  "notifications/getAllNotifications",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await NotificationService.getNotifications(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createNotification = createAsyncThunk(
  "notification/createNotification",
  async (data, { rejectWithValue }) => {
    try {
      const response = await NotificationService.createNotification(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
//n'existe pas pour l'instant
export const updateNotification = createAsyncThunk(
  "notifications/updateNotification",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await NotificationService.updateNotification(id, data);
      return { id, updatedNotification: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notifications/deleteNotification",
  async (id, { rejectWithValue }) => {
    try {
      await NotificationService.deleteNotification(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const validateNotification = createAsyncThunk(
  "notifications/validateNotification",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NotificationService.validateNotification(id);
      return { id, updatedNotification: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const banneNotification = createAsyncThunk(
  "notifications/banneNotification",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await NotificationService.banneNotification(id, data);
      return { id, updatedNotification: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOneNotification = createAsyncThunk(
  "notifications/fetchOneNotification",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NotificationService.getNotification(id);
      return response.data.notificationForAllUsers;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  notifications: [],
  notification: {},
  loading: false,
  error: null,
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch notifications
      .addCase(getAllNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Create Notification
      .addCase(createNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.notifications.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update notification
      .addCase(updateNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        const { id, updatedNotification } = action.payload;
        const index = state.notifications.findIndex(
          (notification) => notification.id === id
        );
        if (index !== -1) {
          state.notifications[index] = {
            ...state.notifications[index],
            ...updatedNotification,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete notification
      .addCase(deleteNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        const notificationId = action.payload;
        state.notifications = state.notifications.filter(
          (notification) => notification._id !== notificationId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      //fetch one notification
      .addCase(fetchOneNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.notification = action.payload;
        state.error = null;
      })
      .addCase(fetchOneNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }) // validate notification
      .addCase(validateNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(validateNotification.fulfilled, (state, action) => {
        const { id, updatedNotification } = action.payload;
        const index = state.notifications.findIndex(
          (notification) => notification.id === id
        );
        if (index !== -1) {
          state.notifications[index] = {
            ...state.notifications[index],
            ...updatedNotification,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(validateNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // banne notification
      .addCase(banneNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(banneNotification.fulfilled, (state, action) => {
        const { id, updatedNotification } = action.payload;
        const index = state.notifications.findIndex(
          (notification) => notification.id === id
        );
        if (index !== -1) {
          state.notifications[index] = {
            ...state.notifications[index],
            ...updatedNotification,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(banneNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default notificationSlice.reducer;
export const { showLoading, hideLoading, hideError } =
  notificationSlice.actions;
