const dev = {
  // API_ENDPOINT_URL: "http://localhost:5000/",
  API_ENDPOINT_URL: "https://backend.win-guide.com/",
}

const prod = {
  API_ENDPOINT_URL: "https://backend.win-guide.com/",
}

const test = {
  API_ENDPOINT_URL: "https://backend.win-guide.com/",
}

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev
    case "production":
      return prod
    case "test":
      return test
    default:
      break
  }
}

export const env = getEnv()
