import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FaqService from "services/FaqService";

export const getAllFaqs = createAsyncThunk(
  "faqs/getAllFaqs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await FaqService.getFaqs();
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createFaq = createAsyncThunk(
  "faq/createFaq",
  async (data, { rejectWithValue }) => {
    try {
      const response = await FaqService.createFaq(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const updateFaq = createAsyncThunk(
  "faqs/updateFaq",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await FaqService.updateFaq(id, data);
      return { id, updatedFaq: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const deleteFaq = createAsyncThunk(
  "faqs/deleteFaq",
  async (id, { rejectWithValue }) => {
    try {
      await FaqService.deleteFaq(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOneFaq = createAsyncThunk(
  "faqs/fetchOneFaq",
  async (id, { rejectWithValue }) => {
    try {
      const response = await FaqService.getFaq(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  faqs: [],
  faq: {},
  loading: false,
  error: null,
};

const faqSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch faqs
      .addCase(getAllFaqs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllFaqs.fulfilled, (state, action) => {
        state.loading = false;
        state.faqs = action.payload;
      })
      .addCase(getAllFaqs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // Create Faq
      .addCase(createFaq.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createFaq.fulfilled, (state, action) => {
        state.faqs.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update faq
      .addCase(updateFaq.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFaq.fulfilled, (state, action) => {
        const { id, updatedFaq } = action.payload;
        const index = state.faqs.findIndex((faq) => faq.id === id);
        if (index !== -1) {
          state.faqs[index] = {
            ...state.faqs[index],
            ...updatedFaq,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete faq
      .addCase(deleteFaq.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        const faqId = action.payload;
        state.faqs = state.faqs.filter((faq) => faq._id !== faqId);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOneFaq.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneFaq.fulfilled, (state, action) => {
        state.loading = false;
        state.faq = action.payload;
        state.error = null;
      })
      .addCase(fetchOneFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default faqSlice.reducer;
export const { showLoading, hideLoading, hideError } = faqSlice.actions;
