import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const NotificationService = {};
NotificationService.getNotifications = function (page) {
  return fetch({
    method: "get",
    url: `api/notificationForAllUsers/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
NotificationService.createNotification = function (data) {
  return fetch({
    method: "post",
    url: `api/notificationForAllUsers/send`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
NotificationService.getNotification = function (id) {
  return fetch({
    method: "get",
    url: `api/notificationForAllUsers/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
NotificationService.deleteNotification = function (id) {
  return fetch({
    method: "delete",
    url: `api/notificationForAllUsers/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
//n'existe pas pour l'instant
NotificationService.updateNotification = function (id, data) {
  return fetch({
    method: "put",
    url: `api/notificationForAllUsers/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
NotificationService.deleteNotifications = function () {
  return fetch({
    method: "delete",
    url: `api/companiesNotification/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
NotificationService.validateNotification = function (id) {
  return fetch({
    method: "post",
    url: `api/notificationForAllUsers/validateAccount/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
NotificationService.banneNotification = function (id, data) {
  return fetch({
    method: "post",
    url: `api/notificationForAllUsers/bannedAccount/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default NotificationService;
