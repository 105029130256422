import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const DomainService = {};
DomainService.getDomains = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/companiesDomain/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
DomainService.createDomain = function (data) {
  return fetch({
    method: "post",
    url: `api/companiesDomain/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
DomainService.getDomain = function (id) {
  return fetch({
    method: "get",
    url: `api/companiesDomain/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
DomainService.deleteDomain = function (id) {
  return fetch({
    method: "delete",
    url: `api/companiesDomain/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
DomainService.updateDomain = function (id, data) {
  return fetch({
    method: "put",
    url: `api/companiesDomain/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
DomainService.deleteDomains = function () {
  return fetch({
    method: "delete",
    url: `api/companiesDomain/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default DomainService;
