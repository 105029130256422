import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const AssociationService = {};
AssociationService.getAssociations = function (page) {
  return fetch({
    method: "get",
    url: `api/association/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AssociationService.createAssociation = function (data) {
  return fetch({
    method: "post",
    url: `api/association/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AssociationService.getAssociation = function (id) {
  return fetch({
    method: "get",
    url: `api/association/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AssociationService.deleteAssociation = function (id) {
  return fetch({
    method: "delete",
    url: `api/association/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AssociationService.updateAssociation = function (id, data) {
  return fetch({
    method: "put",
    url: `api/association/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
AssociationService.deleteAssociations = function () {
  return fetch({
    method: "delete",
    url: `api/association/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default AssociationService;
