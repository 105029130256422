import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit"
import ClientService from "services/ClientService"

export const getAllClients = createAsyncThunk(
  "clients/getAllClients",
  async (page = 1, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await ClientService.getClients(page)
      dispatch(clearStates())
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const searchClients = createAsyncThunk(
  "clients/searchClients",
  async ({ page = 1, value, country }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setCurrentPage(page))
      const response = await ClientService.searchClients(page, value, country)
      dispatch(clearStates())

      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const createClient = createAsyncThunk(
  "client/createClient",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ClientService.createClient(data)
      return response
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
//n'existe pas pour l'instant
export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ClientService.updateClient(id, data)
      return { id, updatedClient: response.data }
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const deleteClient = createAsyncThunk(
  "clients/deleteClient",
  async (id, { rejectWithValue }) => {
    try {
      await ClientService.deleteClient(id)
      return id
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const validateClient = createAsyncThunk(
  "clients/validateClient",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ClientService.validateClient(id)
      return { id, updatedClient: response.data }
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
export const banneClient = createAsyncThunk(
  "clients/banneClient",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ClientService.banneClient(id, data)
      return { id, updatedClient: response.data }
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)

export const fetchOneClient = createAsyncThunk(
  "clients/fetchOneClient",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ClientService.getClient(id)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response || "Erreur")
    }
  }
)
const initialState = {
  clients: [],
  client: {},
  pagesRemain: false,
  loading: false,
  error: null,
  totalItems: null,
  searchValue: null,
  searchCountry: null,
  currentPage: 1,
}

const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true
    },
    hideLoading: (state, action) => {
      state.loading = false
    },
    hideError: (state, action) => {
      state.error = null
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setSearchCountry: (state, action) => {
      state.searchCountry = action.payload
    },
    clearStates: (state) => {
      // state.currentPage = 1
      state.clients = []
      state.totalItems = null
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch clients
      .addCase(getAllClients.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.loading = false
        state.clients = action.payload.data
        state.totalItems = action.payload.totalItems
      })
      .addCase(getAllClients.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      .addCase(searchClients.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(searchClients.fulfilled, (state, action) => {
        state.loading = false
        state.clients = action.payload.data
        state.totalItems = action.payload.totalItems
      })
      .addCase(searchClients.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      // Create Client
      .addCase(createClient.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.clients.push(action.payload)
        state.loading = false
        state.error = null
      })
      .addCase(createClient.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      // Update client
      .addCase(updateClient.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        const { id, updatedClient } = action.payload
        const index = state.clients.findIndex((client) => client.id === id)
        if (index !== -1) {
          state.clients[index] = {
            ...state.clients[index],
            ...updatedClient,
          }
        }
        state.loading = false
        state.error = null
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
      // Delete client
      .addCase(deleteClient.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        const clientId = action.payload
        state.clients = state.clients.filter(
          (client) => client._id !== clientId
        )
        state.loading = false
        state.error = null
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      //fetch one client
      .addCase(fetchOneClient.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchOneClient.fulfilled, (state, action) => {
        state.loading = false
        state.client = action.payload
        state.error = null
      })
      .addCase(fetchOneClient.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      }) // validate client
      .addCase(validateClient.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(validateClient.fulfilled, (state, action) => {
        const { id, updatedClient } = action.payload
        const index = state.clients.findIndex((client) => client.id === id)
        if (index !== -1) {
          state.clients[index] = {
            ...state.clients[index],
            ...updatedClient,
          }
        }
        state.loading = false
        state.error = null
      })
      .addCase(validateClient.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      }) // banne client
      .addCase(banneClient.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(banneClient.fulfilled, (state, action) => {
        const { id, updatedClient } = action.payload
        const index = state.clients.findIndex((client) => client.id === id)
        if (index !== -1) {
          state.clients[index] = {
            ...state.clients[index],
            ...updatedClient,
          }
        }
        state.loading = false
        state.error = null
      })
      .addCase(banneClient.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message
      })
  },
})
export default clientSlice.reducer
export const {
  showLoading,
  hideLoading,
  hideError,
  setCurrentPage,
  setSearchValue,
  setSearchCountry,
  clearStates,
} = clientSlice.actions
