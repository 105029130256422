import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ClaimService from "services/ClaimService";

export const getAllClaims = createAsyncThunk(
  "claims/getAllClaims",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await ClaimService.getClaims(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createClaim = createAsyncThunk(
  "claim/createClaim",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ClaimService.createClaim(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const finishedStatusClaim = createAsyncThunk(
  "claims/finishedStatusClaim",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ClaimService.finishedStatusClaim(id);
      return { id, updatedClaim: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const deleteClaim = createAsyncThunk(
  "claims/deleteClaim",
  async (id, { rejectWithValue }) => {
    try {
      await ClaimService.deleteClaim(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOneClaim = createAsyncThunk(
  "claims/fetchOneClaim",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ClaimService.getClaim(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  claims: [],
  claim: {},
  loading: false,
  error: null,
};

const claimSlice = createSlice({
  name: "claims",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch claims
      .addCase(getAllClaims.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllClaims.fulfilled, (state, action) => {
        state.loading = false;
        state.claims = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllClaims.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // Create Claim
      .addCase(createClaim.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createClaim.fulfilled, (state, action) => {
        state.claims.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createClaim.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update claim
      .addCase(finishedStatusClaim.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(finishedStatusClaim.fulfilled, (state, action) => {
        const { updatedClaim } = action.payload;
        const index = state.claims.findIndex(
          (claim) => claim.id === updatedClaim.id
        );
        if (index !== -1) {
          state.claims[index] = {
            ...state.claims[index],
            ...updatedClaim,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(finishedStatusClaim.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete claim
      .addCase(deleteClaim.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteClaim.fulfilled, (state, action) => {
        const claimId = action.payload;
        state.claims = state.claims.filter((claim) => claim._id !== claimId);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteClaim.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOneClaim.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneClaim.fulfilled, (state, action) => {
        state.loading = false;
        state.claim = action.payload;
        state.error = null;
      })
      .addCase(fetchOneClaim.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default claimSlice.reducer;
export const { showLoading, hideLoading, hideError } = claimSlice.actions;
