import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { AUTH_TOKEN, REFRESH_TOKEN } from "constants/AuthConstant"
import FirebaseService from "services/FirebaseService"
import AuthService from "services/AuthService"

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  refresh: localStorage.getItem(REFRESH_TOKEN) || null,
  didRefresh: false,
  sendEmailInfo: { receiver: "", subject: "", message: "" },
  sendEmailModelIsOpen: false,
}

export const signIn = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    const { email, password } = data
    try {
      const response = await AuthService.login({ email, password })
      const token = response.data.token
      const refresh_token = response.data.refresh_token
      localStorage.setItem(AUTH_TOKEN, token)
      localStorage.setItem(REFRESH_TOKEN, refresh_token)
      return response.data
    } catch (err) {
      return rejectWithValue(err.response?.data?.error || "Error")
    }
  }
)

export const signUp = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    const { email, password } = data
    try {
      const response = await AuthService.register({ email, password })
      const token = response.data.token
      const refresh_token = response.data.refresh_token
      localStorage.setItem(AUTH_TOKEN, token)
      localStorage.setItem(REFRESH_TOKEN, refresh_token)
      return response.data
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error")
    }
  }
)
export const refresh = createAsyncThunk(
  "auth/refresh",
  async (args, { rejectWithValue }) => {
    try {
      const response = await AuthService.refresh()
      const newAccessToken = response.data.accessToken
      //   const rfToken = response.data.newRefreshToken;
      localStorage.setItem(AUTH_TOKEN, newAccessToken)
      //   localStorage.setItem(REFRESH_TOKEN, rfToken);

      return newAccessToken
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Erreur")
    }
  }
)

export const signOut = createAsyncThunk("auth/logout", async () => {
  const response = await FirebaseService.signOutRequest()
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.removeItem(REFRESH_TOKEN)
  return response.data
})

export const getUser = createAsyncThunk(
  "auth/currentUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.getUser()
      return response.data
    } catch (err) {
      return rejectWithValue(err.response?.data.message || "Erreur")
    }
  }
)

export const sendMail = createAsyncThunk(
  "auth/sendMail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AuthService.sendMail(data)
      return response.data
    } catch (err) {
      return rejectWithValue(err.response?.data.message || "Erreur")
    }
  }
)

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth()
      const token = response.data.token
      const refresh_token = response.data.refresh_token
      localStorage.setItem(AUTH_TOKEN, token)
      localStorage.setItem(REFRESH_TOKEN, refresh_token)
      return response.data
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error")
    }
  }
)

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth()
      const token = response.data.token
      const refresh_token = response.data.refresh_token
      localStorage.setItem(AUTH_TOKEN, token)
      localStorage.setItem(REFRESH_TOKEN, refresh_token)
      return response.data
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error")
    }
  }
)

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false
      state.redirect = "/"
      state.token = action.payload
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload
      state.showMessage = true
      state.loading = false
    },
    hideAuthMessage: (state) => {
      state.message = ""
      state.showMessage = false
    },
    signOutSuccess: (state) => {
      state.loading = false
      state.token = null
      state.refresh = null
      state.redirect = "/"
    },
    showLoading: (state) => {
      state.loading = true
    },
    signInSuccess: (state, action) => {
      state.loading = false
      state.token = action.payload
    },
    setEmailModelVisibility: (state, action) => {
      state.sendEmailModelIsOpen = action.payload
    },
    setSendEmailData: (state, action) => {
      state.sendEmailInfo = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false
        state.redirect = "/"
        state.token = action.payload.token
        state.refresh = action.payload.refresh_token
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload
        state.showMessage = true
        state.loading = false
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false
        state.token = null
        state.refresh = null
        state.redirect = "/"
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false
        state.token = null
        state.refresh = null
        state.redirect = "/"
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false
        state.user = action.payload
      })
      .addCase(getUser.rejected, (state, action) => {
        state.message = action.payload
        state.showMessage = true
        state.loading = false
      })
      .addCase(sendMail.pending, (state) => {
        state.loading = true
      })
      .addCase(sendMail.fulfilled, (state, action) => {
        state.loading = false
      })
      .addCase(sendMail.rejected, (state, action) => {
        state.message = action.payload
        state.showMessage = true
        state.loading = false
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false
        state.redirect = "/"
        state.token = action.payload.token
        state.refresh = action.payload.refresh_token
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload
        state.showMessage = true
        state.loading = false
      }) // refresh token
      .addCase(refresh.pending, (state) => {
        state.loading = true
      })
      .addCase(refresh.fulfilled, (state, action) => {
        state.loading = false
        state.token = action.payload
        // state.refresh = action.payload.rfToken;
        state.didRefresh = true
        // window.location.reload();
      })
      .addCase(refresh.rejected, (state, action) => {
        state.message = action.payload
        state.showMessage = true
        state.loading = false
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false
        state.redirect = "/"
        state.token = action.payload
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload
        state.showMessage = true
        state.loading = false
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false
        state.redirect = "/"
        state.token = action.payload
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload
        state.showMessage = true
        state.loading = false
      })
  },
})

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  setEmailModelVisibility,
  setSendEmailData,
} = authSlice.actions

export default authSlice.reducer
