import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const ClaimService = {};
ClaimService.getClaims = function (page) {
  return fetch({
    method: "get",
    url: `api/claim/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClaimService.createClaim = function (data) {
  return fetch({
    method: "post",
    url: `api/claim/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClaimService.getClaim = function (id) {
  return fetch({
    method: "get",
    url: `api/claim/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClaimService.deleteClaim = function (id) {
  return fetch({
    method: "delete",
    url: `api/claim/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClaimService.finishedStatusClaim = function (id) {
  return fetch({
    method: "post",
    url: `api/claim/finishedStatus/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClaimService.deleteClaims = function () {
  return fetch({
    method: "delete",
    url: `api/claim/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default ClaimService;
