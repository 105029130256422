import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllCountries = createAsyncThunk(
  "countries/getAllCountires",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://restcountries.com/v3.1/all?fields=name"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  countries: [],
  loading: false,
  error: null,
};
const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCountries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.countries = action.payload;
      })
      .addCase(getAllCountries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default countrySlice.reducer;
export const { showLoading, hideLoading, hideError } = countrySlice.actions;
