import fetch from "auth/FetchInterceptor"
import { AUTH_TOKEN, REFRESH_TOKEN } from "constants/AuthConstant"

const AuthService = {}

AuthService.login = function (data) {
  return fetch({
    url: "api/admin/login",
    method: "post",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
}
AuthService.getUser = function () {
  return fetch({
    method: "get",
    url: "api/admin/profil",
    data: {},
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}
AuthService.register = function (data) {
  return fetch({
    url: "/auth/register",
    method: "post",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })
}

AuthService.logout = function () {
  return fetch({
    url: "/auth/logout",
    method: "post",
  })
}

AuthService.loginInOAuth = function () {
  return fetch({
    url: "/auth/loginInOAuth",
    method: "post",
  })
}
AuthService.refresh = function () {
  return fetch({
    method: "get",
    url: "api/refreshToken/accessToken",
    headers: {
      Authorization: `Bearer ${localStorage.getItem(REFRESH_TOKEN)}`,
    },
  })
}

AuthService.sendMail = function (data) {
  return fetch({
    url: "api/admin/sendMail",
    method: "post",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  })
}

export default AuthService
