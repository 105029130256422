// statSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import StatsService from "services/StatsService";

// Fonction pour formater la date en yyyy/mm/dd
const formatDate = (date) => {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
  const dd = String(date.getDate()).padStart(2, "0");
  return `${yyyy}/${mm}/${dd}`;
};

// Fonction pour générer les dates des 10 derniers jours
const generateLast10Days = () => {
  const dates = [];
  for (let i = 0; i < 10; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    dates.push(formatDate(date));
  }
  return dates;
};
// Fonction pour formater la date en "dd MMM" pour les catégories
const formatCategoryDate = (date) => {
  const options = { day: "2-digit", month: "short" };
  return date.toLocaleDateString("en-US", options);
};
export const getNumberOfCustomers = createAsyncThunk(
  "stats/getNumberOfCustomers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await StatsService.getNumberOfCustomers();

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getNumberOfAmbassadors = createAsyncThunk(
  "stats/getNumberOfAmbassadors",
  async (_, { rejectWithValue }) => {
    try {
      const response = await StatsService.getNumberOfAmbassadors();

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getNumberOfAmbassadorsAndCustomers = createAsyncThunk(
  "stats/getNumberOfAmbassadorsAndCustomers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await StatsService.getNumberOfAmbassadorsAndCustomers();

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getNumberOfCompanies = createAsyncThunk(
  "stats/getNumberOfCompanies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await StatsService.getNumberOfCompanies();

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getNumberOfRelationships = createAsyncThunk(
  "stats/getNumberOfRelationships",
  async (_, { rejectWithValue }) => {
    try {
      const response = await StatsService.getNumberOfRelationships();

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getBestCompanies = createAsyncThunk(
  "stats/getBestCompanies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await StatsService.getBestCompanies();

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getUsageRateOfSpecificFeatures = createAsyncThunk(
  "stats/getUsageRateOfSpecificFeatures",
  async (_, { rejectWithValue }) => {
    try {
      const dates = generateLast10Days();
      const promises = dates.map((date) =>
        StatsService.getUsageRateOfSpecificFeaturesByDate(date)
      );

      const responses = await Promise.all(promises);
      const data = responses.map((response) => response.data);

      const donationsData = [];
      const messagesData = [];

      data.forEach((dayData) => {
        donationsData.push(dayData.donations.number);
        messagesData.push(dayData.messages.number);
      });

      const VisitorChartData = {
        series: [
          {
            name: "Donations",
            data: donationsData,
          },
          {
            name: "Messages",
            data: messagesData,
          },
        ],
        categories: dates.map((date) =>
          new Date(date).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
          })
        ),
      };

      return VisitorChartData;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getWinGuideVotes = createAsyncThunk(
  "stats/getWinGuideVotes",
  async (_, { rejectWithValue }) => {
    try {
      const response = await StatsService.getWinGuideVotes();

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  stats: [],
  stat: {},
  numberCustomers: {},
  numberAmbassadors: {},
  numberCustomersAndAmbassadors: {},
  numberCompanies: {},
  numberRelationsihps: {},
  bestCompanies: [],
  usageRates: {},
  votes: [],
  loading: false,
  error: null,
};

const statSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch stats
      .addCase(getNumberOfCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNumberOfCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.numberCustomers = action.payload;
      })
      .addCase(getNumberOfCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // Fetch stats
      .addCase(getNumberOfAmbassadors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNumberOfAmbassadors.fulfilled, (state, action) => {
        state.loading = false;
        state.numberAmbassadors = action.payload;
      })
      .addCase(getNumberOfAmbassadors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch stats
      .addCase(getNumberOfAmbassadorsAndCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getNumberOfAmbassadorsAndCustomers.fulfilled,
        (state, action) => {
          state.loading = false;
          state.numberCustomersAndAmbassadors = action.payload;
        }
      )
      .addCase(getNumberOfAmbassadorsAndCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch stats
      .addCase(getNumberOfCompanies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNumberOfCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.numberCompanies = action.payload;
      })
      .addCase(getNumberOfCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch stats
      .addCase(getNumberOfRelationships.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNumberOfRelationships.fulfilled, (state, action) => {
        state.loading = false;
        state.numberRelationsihps = action.payload;
      })
      .addCase(getNumberOfRelationships.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch stats
      .addCase(getBestCompanies.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBestCompanies.fulfilled, (state, action) => {
        state.loading = false;
        state.bestCompanies = action.payload;
      })
      .addCase(getBestCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch stats
      .addCase(getUsageRateOfSpecificFeatures.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsageRateOfSpecificFeatures.fulfilled, (state, action) => {
        state.loading = false;
        state.usageRates = action.payload;
      })
      .addCase(getUsageRateOfSpecificFeatures.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Fetch stats
      .addCase(getWinGuideVotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWinGuideVotes.fulfilled, (state, action) => {
        state.loading = false;
        state.votes = action.payload;
      })
      .addCase(getWinGuideVotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default statSlice.reducer;
export const { showLoading, hideLoading, hideError } = statSlice.actions;
