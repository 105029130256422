import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AssociationService from "services/AssociationService";

export const getAllAssociations = createAsyncThunk(
  "associations/getAllAssociations",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await AssociationService.getAssociations(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createAssociation = createAsyncThunk(
  "association/createAssociation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AssociationService.createAssociation(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const updateAssociation = createAsyncThunk(
  "associations/updateAssociation",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AssociationService.updateAssociation(id, data);
      return { id, updatedAssociation: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const deleteAssociation = createAsyncThunk(
  "associations/deleteAssociation",
  async (id, { rejectWithValue }) => {
    try {
      await AssociationService.deleteAssociation(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOneAssociation = createAsyncThunk(
  "associations/fetchOneAssociation",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AssociationService.getAssociation(id);

      const assocModified = {
        ...response.data.association,
        logo: [response.data.association.logo],
      };
      return assocModified;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  associations: [],
  association: {},
  loading: false,
  error: null,
};

const associationSlice = createSlice({
  name: "associations",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch associations
      .addCase(getAllAssociations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAssociations.fulfilled, (state, action) => {
        state.loading = false;
        state.associations = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllAssociations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // Create Association
      .addCase(createAssociation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAssociation.fulfilled, (state, action) => {
        state.associations.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createAssociation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update association
      .addCase(updateAssociation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAssociation.fulfilled, (state, action) => {
        const { id, updatedAssociation } = action.payload;
        const index = state.associations.findIndex(
          (association) => association.id === id
        );
        if (index !== -1) {
          state.associations[index] = {
            ...state.associations[index],
            ...updatedAssociation,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateAssociation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete association
      .addCase(deleteAssociation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAssociation.fulfilled, (state, action) => {
        const associationId = action.payload;
        state.associations = state.associations.filter(
          (association) => association._id !== associationId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteAssociation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOneAssociation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneAssociation.fulfilled, (state, action) => {
        state.loading = false;
        state.association = action.payload;
        state.error = null;
      })
      .addCase(fetchOneAssociation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default associationSlice.reducer;
export const { showLoading, hideLoading, hideError } = associationSlice.actions;
