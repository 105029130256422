import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import professionals from "./slices/professionalsSlice";
import domains from "./slices/domainSlice";
import countries from "./slices/countrySlice";
import clients from "./slices/clientSlice";
import associations from "./slices/associationSlice";
import donations from "./slices/donationSlice";
import services from "./slices/serviceSlice";
import relationships from "./slices/relationshipSlice";
import faqs from "./slices/faqSlice";
import admins from "./slices/adminSlice";
import claims from "./slices/claimSlice";
import stats from "./slices/statsSlice";
import notifications from "./slices/notificationSlice";
const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    professionals,
    domains,
    clients,
    countries,
    associations,
    donations,
    services,
    relationships,
    faqs,
    admins,
    claims,
    stats,
    notifications,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
