import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const FaqService = {};
FaqService.getFaqs = function () {
  return fetch({
    method: "get",
    url: `api/faq/getAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
FaqService.createFaq = function (data) {
  return fetch({
    method: "post",
    url: `api/faq/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
FaqService.getFaq = function (id) {
  return fetch({
    method: "get",
    url: `api/faq/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
FaqService.deleteFaq = function (id) {
  return fetch({
    method: "delete",
    url: `api/faq/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
FaqService.updateFaq = function (id, data) {
  return fetch({
    method: "put",
    url: `api/faq/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
FaqService.deleteFaqs = function () {
  return fetch({
    method: "delete",
    url: `api/faq/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default FaqService;
