import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AdminService from "services/AdminService";

export const getAllAdmins = createAsyncThunk(
  "admins/getAllAdmins",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await AdminService.getAdmins(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const getAllControllers = createAsyncThunk(
  "admins/getAllControllers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AdminService.getAllControllers();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createAdmin = createAsyncThunk(
  "admin/createAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AdminService.createAdmin(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
export const createSuperAdmin = createAsyncThunk(
  "admin/createSuperAdmin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AdminService.createSuperAdmin(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const updateAdminToSuperAdmin = createAsyncThunk(
  "admins/updateAdminToSuperAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AdminService.updateAdminToSuperAdmin(id);
      return { id, updatedAdmin: response.data };
    } catch (error) {
      switch (error.response.status) {
        case 400:
          return rejectWithValue(
            "only the first registered super admin can modify admin roles"
          );
        case 401:
          return rejectWithValue("The account is already a super admin");
        case 403:
          return rejectWithValue(
            "You are not authorized to perform this operation"
          );
        case 404:
          return rejectWithValue("admin not found");
        default:
          return rejectWithValue("internal server error");
      }
    }
  }
);
export const updateSuperAdminToAdmin = createAsyncThunk(
  "admins/updateSuperAdminToAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AdminService.updateSuperAdminToAdmin(id);
      return { id, updatedAdmin: response.data };
    } catch (error) {
      switch (error.response.status) {
        case 400:
          return rejectWithValue(
            "only the first registered super admin can modify admin roles"
          );
        case 401:
          return rejectWithValue("The account is already a super admin");
        case 403:
          return rejectWithValue(
            "You are not authorized to perform this operation"
          );
        case 404:
          return rejectWithValue("admin not found");
        default:
          return rejectWithValue("internal server error");
      }
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  "admins/deleteAdmin",
  async (id, { rejectWithValue }) => {
    try {
      await AdminService.deleteAdmin(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOneAdmin = createAsyncThunk(
  "admins/fetchOneAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AdminService.getAdmin(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);
const initialState = {
  controllers: [],
  admins: [],
  admin: {},
  loading: false,
  error: null,
};

const adminSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch admins
      .addCase(getAllAdmins.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAdmins.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllAdmins.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) // fetch controllers
      .addCase(getAllControllers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllControllers.fulfilled, (state, action) => {
        state.loading = false;
        state.controllers = action.payload;
      })
      .addCase(getAllControllers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Create Admin
      .addCase(createAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.admins.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }) // create super admin
      .addCase(createSuperAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSuperAdmin.fulfilled, (state, action) => {
        state.admins.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createSuperAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update admin to super admin
      .addCase(updateAdminToSuperAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAdminToSuperAdmin.fulfilled, (state, action) => {
        const { id, updatedAdmin } = action.payload;
        const index = state.admins.findIndex((admin) => admin.id === id);
        if (index !== -1) {
          state.admins[index] = {
            ...state.admins[index],
            ...updatedAdmin,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateAdminToSuperAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }) //updateSuperAdminToAdmin
      .addCase(updateSuperAdminToAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSuperAdminToAdmin.fulfilled, (state, action) => {
        const { id, updatedAdmin } = action.payload;
        const index = state.admins.findIndex((admin) => admin.id === id);
        if (index !== -1) {
          state.admins[index] = {
            ...state.admins[index],
            ...updatedAdmin,
          };
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateSuperAdminToAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete admin
      .addCase(deleteAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        const adminId = action.payload;
        state.admins = state.admins.filter((admin) => admin._id !== adminId);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOneAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.admin = action.payload;
        state.error = null;
      })
      .addCase(fetchOneAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default adminSlice.reducer;
export const { showLoading, hideLoading, hideError } = adminSlice.actions;
